<template>
    <div class="service">
        <div class="service-head">
            <h3>业务及产品</h3>
            <h4>BUSSINESS & PRODUCTS</h4>
        </div>
        <div class="maodian">
            <!-- <a-anchor @click="tobox" :affix='false'>
                <a-anchor-link
                :style="{
                        borderBottom:
                            rowtitle == item.title
                                ? '3px solid purple'
                                : '3px solid transparent'
                    }"
                    v-for="(item, index) in list"
                    :key="index"
                    :href="item.href"
                    :title="item.title"
                />
            </a-anchor> -->
            <anchor :list="list"></anchor>
        </div>
        <div class="product">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png"
                     alt="" />
            </div>
            <div class="center "
                 id="wineProduct">
                <img src="../assets/images/logo/圆9排10列.png"
                     alt=""
                     class="img-common" />
                <div class="wineProduct">
                    <div class="wineTitle">自营白酒产品</div>
                    <div style="margin:20px 0">
                        <img src="../assets/images/business/point.png"
                             style="width:40px;height:20px"
                             alt="" />
                    </div>
                    <div class="brand">BRANDING CUSTOM</div>
                </div>
                <div>
                    <a-carousel autoplay>
                        <div class="wines">
                            <img src="../assets/images/business/wines.png"
                                 alt="" />
                        </div>
                        <div class="wines">
                            <img src="../assets/images/business/wines.png"
                                 alt="" />
                        </div>
                        <div class="wines">
                            <img src="../assets/images/business/wines.png"
                                 alt="" />
                        </div>
                        <div class="wines">
                            <img src="../assets/images/business/wines.png"
                                 alt="" />
                        </div>
                    </a-carousel>
                </div>
            </div>
            <div class="right">
                <img src="../assets/images/logo/科技线@2x.png"
                     alt="" />
            </div>
        </div>
        <div class="operating"
             id="operating">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png"
                     alt="" />
            </div>
            <div class="center">
                <img src="../assets/images/logo/圆9排10列.png"
                     alt=""
                     class="img-common" />
                <div class="wineProduct">
                    <div class="wineTitle">次新酒运营</div>
                    <div style="margin:20px 0">
                        <img src="../assets/images/business/point.png"
                             style="width:40px;height:20px"
                             alt="" />
                    </div>
                    <div class="brand">OLD NEW WINE</div>
                </div>
                <div class="wines">
                    <img src="../assets/images/business/operatingwine.png"
                         alt="" />
                </div>
            </div>
            <div class="right">
                <img src="../assets/images/logo/科技线@2x.png"
                     alt="" />
            </div>
        </div>
        <div class="hjjapp"
             id="hjjapp">
            <div class="left">
                <img src="../assets/images/logo/标题装饰@2x.png"
                     alt="" />
            </div>
            <div class="center"
                 style="">
                <div class="center-right">
                    <div class="wineProduct">
                        <div class="wineTitle">好酒集APP</div>
                        <div style="margin:20px 0">
                            <img src="../assets/images/business/point.png"
                                 style="width:40px;height:20px"
                                 alt="" />
                        </div>
                        <div class="brand">HAOJIUJI APP</div>
                    </div>
                    <div class="hjjIntroduce">
                        <div class="introduceTitle">
                            好酒集-酒类直供平台：
                        </div>
                        <div class="introduceContent">
                            国内领先的白酒供应链流通综合服务平台，好酒集秉承“帮你卖好酒”的经营理念，致力于为白酒供应链上下游酒企、酒商提供集采购、销售、交易、技术、物流、供应链金融等功能于一体的综合服务平台，为用户带来更丰富的产品品类、更安全可靠的酒饮品质、更优惠实在的商品价格、更全面周到的细致服务，更省钱、省事、省心的全新采购体验。
                            好酒集的上线帮助品牌厂商、经销商、零售终端打造新零售时代的互联网转型升级解决方案，通过整合、优化供应链资源，提升供应链运转效率，降低供应链各环节运营成本，最终推动传统酒业成功转型升级。
                        </div>
                    </div>
                    <div class="ewcode">
                        <div class="ewmBox">
                            <div style="margin-top:20px;">
                                <img src="@/assets/images/logo/b2Code.png"
                                     style="width:138px;height:138px"
                                     alt="" />
                            </div>
                            <div class="download">
                                扫码下载商户端app
                            </div>
                        </div>
                        <div class="ewmBox">
                            <div style="margin-top:20px;">
                                <img src="@/assets/images/logo/b3Code.png"
                                     style="width:138px;height:138px"
                                     alt="" />
                            </div>
                            <div class="download">
                                扫码下载采购端app
                            </div>
                        </div>
                    </div>
                </div>
                <div class="center-left">
                    <img src="../assets/images/business/app.png"
                         style="width:546px;"
                         alt="" />
                </div>
            </div>
            <div class="right">
                <img src="../assets/images/logo/科技线@2x.png"
                     alt="" />
            </div>
        </div>
        <div class="comingsoon">
            更多产品，即将上线……
        </div>
        <!-- <footerBox></footerBox> -->
    </div>
</template>

<script>
import anchor from '../components/anchor/anchor.vue'
export default {
    name: 'serviceProduct',
    components: {
        anchor,
    },
    data() {
        return {
            list: [
                {
                    title: '自营白酒产品',
                    href: '#wineProduct',
                },
                {
                    title: '次新酒运营',
                    href: '#operating',
                },
                {
                    title: '好酒集APP',
                    href: '#hjjapp',
                },
            ],
            rowtitle: '自营白酒产品',
        }
    },
    methods: {
        tobox(i, e) {
            console.log(i, e)
            this.rowtitle = e.title
        },
    },
}
</script>

<style lang="scss" scoped>
// 修改走马灯的下边按钮样式
/deep/ .slick-dots {
    position: relative;
    bottom: -10px;
    left: -560px;
    li {
        button {
            width: 10px;
            height: 10px;
            border: 2px solid #0037cc;
            border-radius: 10px;
        }
    }
}
// 走马灯active状态下改变样式
/deep/.ant-carousel .slick-dots li.slick-active button {
    background: #0037cc;
}

// 锚点样式修改
/deep/ .ant-anchor-wrapper {
    border: 1px solid #f0f2f5;
}
/deep/ .ant-anchor {
    width: 1280px;
    margin: 0 auto;
    height: 69px;
    display: flex;
    .ant-anchor-link {
        line-height: 69px;
        padding: 0 7px;
    }
}
// /deep/ .ant-anchor-link-active{
//     border-bottom:3px solid purple
// }
/deep/ .ant-anchor-ink-ball {
    visibility: hidden;
}
/deep/ .ant-anchor-ink::before {
    visibility: hidden;
}

// }
/* For demo */
.ant-carousel >>> .slick-slide {
    text-align: center;
    /* height: 160px;
    line-height: 160px;
    background: #364d79; */
    overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
    display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
    opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
    color: #fff;
}

.service-head {
    margin-top: 100px;
    width: 100%;
    height: 400px;
    padding-left: calc((100% - 1280px) / 2);
    letter-spacing: 8px;
    box-sizing: border-box;
    background: url('../assets/images/business/business.png');
    background-size: 100% 400px;
    background-repeat: no-repeat;
    font-size: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h3 {
        color: #fff;
        font-weight: bold;
    }
    h4 {
        color: #fff;
        font-size: 30px;
        font-weight: bold;
    }
}
.maodian {
    border-bottom: 1px solid #f0f2f5;
    height: 69px;
    li {
        list-style: none;
        // width: 96px;
        line-height: 69px;
        margin-right: 30px;
        font-family: HarmonyOS Sans SC;
        color: #111a37;
        font-weight: bold;
        font-size: 16px;
        border-bottom: 3px solid transparent;
        cursor: pointer;
    }
}
.product {
    width: 100%;
    padding: 120px 0 140px 0;
    display: flex;
    position: relative;
    .left,
    .right {
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left {
        margin-top: 25px;
    }
    .right {
        margin-top: 750px;
    }
}
.wineProduct {
    width: 321px;
    // margin-top: 100px;
    .wineTitle {
        width: 283px;
        height: 63px;
        font-size: 46px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        line-height: 59px;
        color: #00227d;
    }
    .brand {
        width: 321px;
        height: 93px;
        font-size: 26px;
        font-family: HarmonyOS Sans SC;
        font-weight: 900;
        line-height: 59px;
        color: #2b4079;
        opacity: 0.2;
    }
}
.wines {
    width: 1280px;
    height: 612px;
    border-radius: 8px;
    img {
        border-radius: 8px;
        width: 1280px;
        height: 612px;
    }
}
.operating {
    width: 100%;
    height: 1076px;
    background-image: url('../assets/images/business/operating.png');
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    position: relative;
    z-index: -2;
    .wineProduct {
        margin: 0;
    }
    .left,
    .right {
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left {
        margin-top: -750px;
    }
    .right {
        margin-top: 560px;
    }
}
.hjjapp {
    width: 100%;
    display: flex;
    .center {
        display: flex;
        justify-content: space-between;
        padding: 140px 0;
    }
    .left,
    .right {
        width: calc((100% - 1280px) / 2);
        img {
            width: 100%;
        }
    }
    .left {
        margin-top: 160px;
        height: 20px;
    }
    .right {
        margin-top: 530px;
    }
    .hjjIntroduce {
        .introduceTitle {
            width: 220px;
            height: 26px;
            font-size: 20px;
            font-family: HarmonyOS Sans SC;
            font-weight: bold;
            line-height: 36px;
            color: #00227d;
            opacity: 1;
        }
        .introduceContent {
            margin-top: 30px;
            width: 590px;
            height: 187px;
            font-size: 14px;
            font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma,
                'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb',
                sans-serif;
            font-weight: 400;
            line-height: 28px;
            color: #111a37;
            opacity: 1;
        }
    }
    .ewcode {
        display: flex;
        // margin-bottom: 200px;
        .ewmBox {
            margin: 0 20px;
            width: 160px;
            height: 201px;
            box-shadow: 0 0 10px rgb(175, 175, 175);
            display: flex;
            justify-content: center;
            // align-items: center;
            flex-wrap: wrap;
            border-radius: 4px;
            .download {
                width: 112px;
                height: 16px;
                font-size: 12px;
                font-family: HarmonyOS Sans SC;
                font-weight: 400;
                color: #111a37;
                opacity: 1;
            }
        }
    }
}

.comingsoon {
    width: 100%;
    line-height: 234px;
    background-image: url('../assets/images/business/comingsoon.png');
    text-align: center;
    font-size: 26px;
    font-family: HarmonyOS Sans SC;
    font-weight: 400;
    color: #2b4079;
}
.center {
    width: 1280px;
    margin: 0 auto;
    position: relative;
}
.img-common {
    width: 326px;
    height: 319px;
    position: absolute;
    top: 105px;
    right: -105px;
    z-index: -1;
}
.underLine {
    list-style: none;
    // width: 96px;
    line-height: 69px;
    margin-right: 30px;
    font-family: HarmonyOS Sans SC;
    color: #111a37;
    font-weight: bold;
    font-size: 16px;
    border-bottom: 3px solid purple;
}
</style>
